@layer components{
  .container {
    @apply max-w-[1140px];
    @apply px-3.5; /* 14px */
    @apply mx-auto;
  }

  .btn-group {
    @apply flex;
    @apply items-center;
    @apply [&>*:not(:first-child):not(&.btn-group-vertical)]:-ml-px;
    @apply [&>*]:focus:z-1;

    &:not(&.btn-group-vertical) {
      &.btn-group-rounded {
        @apply [&>*:first-child]:rounded-l;
        @apply [&>*:last-child]:rounded-r;
      }

      &.btn-group-rounded-full {
        @apply [&>*:first-child]:rounded-l-full;
        @apply [&>*:last-child]:rounded-r-full;
      }
    }

    &.btn-group-vertical {
      @apply flex-col;
      @apply [&>*:not(:first-child)]:-mt-px;
      @apply [&>*]:focus:z-1;
      @apply [&>*]:w-full;

      &.btn-group-rounded {
        @apply [&>*:first-child]:rounded-t;
        @apply [&>*:last-child]:rounded-b;
      }

      &.btn-group-rounded-full {
        @apply [&>*:first-child]:rounded-t-full;
        @apply [&>*:last-child]:rounded-b-full;
      }
    }
  }

  .unStyled-form-elements {
    input, select, textarea {
      @apply border-none;
      @apply shadow-none;
      @apply transition-none;
      @apply focus:border-none;
      @apply focus:outline-none;
      @apply focus:ring-0;
    }
  }
}
