@define-mixin darkColors {
    --bj-primary: 67 126 208; /* #437ED0 */
    --bj-primary-light: 57 136 246; /* #3988F6 */
    --bj-primary-dark: 30 90 172; /* #1E5AAC */

    --bj-secondary: 106 214 77; /* #6AD64D */
    --bj-secondary-light: 101 226 68; /* #65E244 */
    --bj-secondary-dark: 67 193 33; /* #43C121 */

    --bj-warning: 255 196 22; /* #FFC416 */
    --bj-warning-light: 255 193 7; /* #FFC107 */

    --bj-error: 236 73 59; /* #EC493B */
    --bj-error-light: 222 68 55; /* #DE4437 */

    --bj-border-default: 55, 65, 81; /* #374151 - gray 700 */
    --bj-input-border: 90 94 101; /* #5A5E65 */

    --bj-surface: 30 32 34; /* #1E2022 */
    --bj-background: 15 15 16; /* #0F0F10 */
    --bj-on-background-faded: 46 49 52; /* #2E3134 */
    --bj-background-150: 56 65 70; /* #394146 */
    --bj-background-200: 55 65 81; /* #374151 */
    --bj-background-300: 75 85 99; /* #4B5563 */
    --bj-surface-dropdown: var(--bj-on-background-faded);

    --bj-on-surface-strong: 248 249 250; /* #F8F9FA */
    --bj-on-surface-medium: 189 197 209; /* #BDC5D1 */
    --bj-on-surface-light: 151 164 175; /* #97A4AF */
}
