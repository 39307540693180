.slick-slider {
    @apply relative block box-border select-none touch-pan-y px-0 md:px-10
}

.slick-list {
    @apply block m-0 p-0 overflow-hidden
}

.slick-list:focus {
    @apply outline-0
}

.slick-list.dragging {
    @apply cursor-pointer
}

.slick-track {
    @apply flex items-center relative min-w-full top-0 left-0 ml-auto mr-auto py-2
}

.slick-track:before,
.slick-track:after {
    @apply table content-['']
}

.slick-track:after {
    @apply clear-both
}

.slick-loading .slick-track {
    @apply invisible
}

.slick-slide {
    @apply hidden h-full min-h-[1px]
}

[dir='rtl'] .slick-slide {
    @apply float-right
}

.slick-slide img {
    @apply block
}

.slick-slide.slick-loading img {
    @apply hidden
}

.slick-slide.dragging img {
    @apply pointer-events-none
}

.slick-initialized .slick-slide {
    @apply block
}

.slick-loading .slick-slide {
    @apply invisible
}

.slick-vertical .slick-slide {
    @apply block h-auto border border-transparent
}

.slick-arrow.slick-hidden {
    @apply hidden
}

.slick-disabled {
    @apply opacity-20
}


.slick-slider .slick-dots {
    @apply items-center justify-center m-0 py-2 list-none mt-2 overflow-x-auto overflow-y-hidden;
    @apply flex !important;
}


.slick-dots li {
    @apply mx-1.5
}

.slick-dots button {
    @apply block w-2.5 h-2.5 p-0 border-0 rounded-full bg-ink-light/60 indent-[-9999px] sm:w-3 sm:h-3
}


.slick-dots li.slick-active button {
    @apply bg-secondary
}

.slick-track {
    @apply flex items-stretch
}

/* without not(.variable-height) the config option {adaptiveHeight: true,} does not work... */
.slick-slider:not(.variable-height) .slick-track .slick-slide {
    @apply h-auto;
}

.slick-track .slick-slide {
    @apply flex z-1;
}
