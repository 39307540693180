.react-datepicker {
    @apply border-ink-medium bg-surface z-50
}
.react-datepicker .react-datepicker__month-container {
    @apply shadow-2xl border-0
}

.react-datepicker .react-datepicker__header {
    @apply bg-surface  border-b-0 pt-5 bg-white
}

.react-datepicker .react-datepicker__month-text {
    @apply p-3
}

.react-datepicker .react-datepicker__month,.react-datepicker__year {
    @apply bg-white
}

.react-datepicker .react-datepicker__month-text--keyboard-selected {
    @apply bg-secondary
}
.react-datepicker .react-datepicker__month-text--keyboard-selected:hover {
    @apply bg-secondary
}

.react-datepicker .react-datepicker__navigation {
    @apply top-5
}
.react-datepicker .react-datepicker__navigation .react-datepicker__navigation-icon::before {
    @apply border-black
}

/*Year selector*/

.react-datepicker .react-datepicker__year--container {
    @apply shadow-2xl border-0
}

.react-datepicker .react-datepicker__year-text {
    @apply p-3
}

.react-datepicker .react-datepicker__year-wrapper {
    @apply justify-evenly
}
.react-datepicker .react-datepicker__year-text--selected {
    @apply bg-secondary
}
.react-datepicker .react-datepicker__year-text--keyboard-selected {
    @apply bg-secondary
}
.react-datepicker .react-datepicker__year-text--keyboard-selected:hover {
    @apply bg-secondary
}

/*Day Selector*/
.react-datepicker .react-datepicker__day--selected {
    @apply bg-secondary
}
.react-datepicker .react-datepicker__day--keyboard-selected {
     @apply bg-secondary
 }