.bj-slick-image-gallery.slick-slider {
    @apply flex flex-1;
}

.bj-slick-image-gallery .slick-list {
    @apply flex-1;
}

.bj-slick-image-gallery .slick-track {
    @apply h-full items-center;
}

.bj-slick-image-gallery .slick-slide {
    @apply h-full !important;
    @apply relative justify-center cursor-grab;
}

.bj-slick-image-gallery .slick-slide div {
    @apply flex-1;
}
