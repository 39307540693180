@layer components {
  .editor-container {
    @apply relative;
    @apply block;
  }

  .editor {
    @apply flex-auto;
    @apply relative;
    @apply resize-y;
    @apply -z-1;
  }

  .editor-scroller {
    @apply min-h-[150px];
    @apply border-0;
    @apply flex;
    @apply relative;
    @apply outline-none;
    @apply z-0;
    @apply overflow-auto;
    @apply resize-y;
  }

  .editor-image {
    @apply cursor-default;
    @apply inline-block;
    @apply relative;
    @apply select-none;

    img {
      @apply max-w-full;
      @apply cursor-default;

      &.focused {
        @apply outline;
        @apply outline-1;
        @apply outline-offset-2;
        @apply outline-primary-light;
        @apply select-none;

        &.draggable {
          @apply cursor-grab;

          &:active {
            @apply cursor-grabbing;
          }
        }
      }
    }

    .image-caption-container .tree-view-output {
      @apply m-0;
      @apply rounded-none;
    }

    .image-edit-button:hover {
      @apply bg-primary-light/10;
    }

    .image-resizer {
      @apply block;
      @apply size-2;
      @apply absolute;
      @apply bg-primary;
      @apply border;
      @apply border-white;

      &.image-resizer-n {
        @apply -top-1.5;
        @apply left-1/2;
        @apply cursor-n-resize;
      }

      &.image-resizer-ne {
        @apply -top-1.5;
        @apply -right-1.5;
        @apply cursor-ne-resize;
      }

      &.image-resizer-nw {
        @apply -top-1.5;
        @apply -left-1.5;
        @apply cursor-nw-resize;
      }

      &.image-resizer-e {
        @apply top-1/2;
        @apply -right-1.5;
        @apply cursor-e-resize;
      }

      &.image-resizer-se {
        @apply -bottom-1.5;
        @apply -right-1.5;
        @apply cursor-se-resize;
      }

      &.image-resizer-sw {
        @apply -bottom-1.5;
        @apply -left-1.5;
        @apply cursor-sw-resize;
      }

      &.image-resizer-s {
        @apply -bottom-1.5;
        @apply left-1/2;
        @apply cursor-s-resize;
      }

      &.image-resizer-w {
        @apply top-1/2;
        @apply -left-1.5;
        @apply cursor-w-resize;
      }
    }
  }

  .inline-editor-image {
    @apply cursor-default;
    @apply inline-block;
    @apply relative;
    @apply z-1;

    img {
      @apply max-w-full;
      @apply cursor-default;

      &.focused {
        @apply outline;
        @apply outline-1;
        @apply outline-offset-2;
        @apply outline-primary-light;
        @apply select-none;

        &.draggable {
          @apply cursor-grab;

          &:active {
            @apply cursor-grabbing;
          }
        }
      }
    }

    .image-caption-container {
      @apply block;
      @apply min-w-full;
      @apply overflow-hidden;

      .tree-view-output {
        @apply m-0;
        @apply rounded-none;
      }
    }

    &.position-full {
      @apply my-4;
    }

    &.position-left {
      @apply mr-4;
      @apply mt-4;
      @apply float-left;
      @apply w-1/2;
    }

    &.position-right {
      @apply ml-4;
      @apply mt-4;
      @apply float-right;
      @apply w-1/2;
    }
  }
}
