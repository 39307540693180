/* styleguide: https://www.figma.com/file/dSSoEy2PRvOVuuGmqUyqrE/style-guide-web-app */

/* custom mixins must be imported before `tailwindcss/base` */
@import "bj_theme/bj_colors_mixins.css";

@import "tailwindcss/base";
@import "./bj_theme/bj_colors.css";
@import "./bj_theme/bj_general.css";
@import "./bj_theme/bj_typography.css";
@import "./bj_theme/bj-forms.css";
@import "./bj_theme/bj-datepicker.css";
@import "./bj_theme/bj_custom.css";
@import "./bj_theme/bj_slick.css";

@import "tailwindcss/components";
@import "./bj_theme/bj_components.css";
@import "./bj_theme/lexical-editor.css";

@import "tailwindcss/utilities";
@import "./bj_theme/bj_utilities.css";
