@layer base {
	[type='text'],
	[type='email'],
	[type='url'],
	[type='password'],
	[type='number'],
	[type='date'],
	[type='datetime-local'],
	[type='month'],
	[type='search'],
	[type='tel'],
	[type='time'],
	[type='week'],
	[multiple],
	textarea,
	select {
		@apply
		bg-surface
		shadow-sm
		border-input
		p-3
		rounded
		w-full
		text-ink
		placeholder:text-ink-light
		transition-all
		duration-200
		align-top
		scroll-mt-24
		/* focus styles */
		focus:border-primary-light/80
		focus:ring
		focus:ring-primary-light
		focus:ring-opacity-40
		/* invalid styles */
		[&:not(:placeholder-shown)]:invalid:border-error
		[&.hasError]:invalid:border-error
		[&:not(:placeholder-shown)]:invalid:placeholder:text-error/60
		/* invalid focus styles */
		[&:not(:placeholder-shown)]:invalid:focus:border-error/80
		[&:not(:placeholder-shown)]:invalid:focus:ring-error-light
		[&:not(:placeholder-shown)]:invalid:focus:ring-opacity-40
		[&.hasError]:invalid:focus:border-error/80
		[&.hasError]:invalid:focus:ring-error-light
		[&.hasError]:invalid:focus:ring-opacity-40
		[&.hasError]:invalid:placeholder:text-error/60
		/* required styles */
		[&.asOptional]:[&:not(.hasError)]:required:border-input
		[&:not(.hasError)]:required:border-warning
		[&.emptyValue]:[&:not(.hasError)]:required:border-warning
		[&:not(.hasError)]:required:focus:border-primary-light/80
		/* valid styles */
		[&:not(.emptyValue)]:[&:not(:placeholder-shown)]:[&:not(:focus)]:valid:border-secondary
		[&:not(.emptyValue)]:[&:not(:placeholder-shown)]:[&:not(:focus)]:[&:read-only]:border-secondary
		[&.emptyValue]:[&:not(:placeholder-shown)]:[&:not(:focus)]:valid:border-input
    /* readonly & disabled styles */
    [&:read-only]:bg-surface-50
    [&:disabled]:bg-surface-50
		;
	}

	[type='file'] {
		@apply
		bg-surface
		w-full
		rounded
		border
		border-input
		focus:outline-0
		text-ink
		placeholder:text-ink-light
		transition-all
		duration-200
		align-top
		scroll-mt-24
		file:bg-surface-200
		file:border-0
		file:text-ink
		file:rounded
		file:py-3
		file:px-3
		file:mr-3
		file:cursor-pointer
	},

	[type='file'] {
		@apply
		bg-surface
		w-full
		rounded
		border
		border-input
		focus:outline-0
		text-ink
		placeholder:text-ink-light
		transition-all
		duration-200
		align-top
		scroll-mt-24
		file:bg-surface-200
		file:border-0
		file:text-ink
		file:rounded
		file:py-3
		file:px-3
		file:mr-3
		file:cursor-pointer
	},

	[type='radio'],
	[type='checkbox'] {
		@apply
		w-4.5
		h-4.5
		text-primary-light
		border-input
		shadow-sm
		bg-surface
		checked:bg-primary-light
		transition-all
		duration-200
		/* invalid styles */
		[&.hasError]:invalid:border-error/80
		[&.hasError]:invalid:focus:ring-error-light
		[&.hasError]:invalid:focus:ring-opacity-15
		/* required styles */
		[&.asOptional]:[&:not(.hasError)]:required:border-input
		[&:not(.hasError)]:required:border-warning
		/* valid styles */
		[&:not(.hasError)]:required:valid:border-input
		[&:not(.hasError)]:required:checked:valid:border-transparent;
	}

	[type='radio'] {
		@apply
		checked:bg-none
		ring-2
		ring-surface
		ring-inset
		focus:ring-inset
		focus:ring-0;
	}

	[type='checkbox'] {
		@apply
		focus:ring
		focus:ring-offset-0
		focus:ring-primary-light
		focus:ring-opacity-15
		rounded;
	}
}

/* CSS for hiding arrow up and down buttons on input='number' */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}

/*
  Change Autocomplete Styles in WebKit Browsers
  source: https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  /* Changes the background by using a large inset box-shadow to cover autofill color */
  -webkit-box-shadow: 0 0 0 1000px rgb(var(--bj-on-background-faded)) inset;

  /* Smooth transition to avoid autofill background flashing back to default */
  transition: background-color 5000s ease-in-out 0s;

  /* Set the autofill text color */
  -webkit-text-fill-color: rgb(var(--bj-on-surface-strong));
}
