/**
 * Markdown styles, using Tailwind CSS classes.
 */
.markdown {
  @apply text-sm;
}

.markdown h1, .markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
  @apply mt-3 mb-2 font-semibold;
}

.markdown h1 {
  @apply text-3xl font-bold;
}

.markdown h2 {
  @apply text-2xl font-bold;
}

.markdown h3 {
  @apply text-xl font-bold;
}

.markdown h4 {
  @apply text-lg font-bold;
}

.markdown h5 {
  @apply text-base font-semibold;
}

.markdown h6 {
  @apply text-sm font-semibold;
}

.markdown p {
  @apply text-sm;
}

.markdown ul, .markdown ol {
  @apply mt-0 mb-3 pl-3;
}

.markdown ul {
  @apply list-disc;
}

.markdown ol {
  @apply list-decimal;
}

.markdown li {
  @apply mb-2;
}

.markdown blockquote {
  @apply mx-3 px-2 italic;
}

.markdown code {
  @apply font-mono bg-gray-400 px-3 py-2 rounded;
}

.markdown pre {
  @apply p-4;
}

.markdown pre code {
  @apply text-sm font-mono p-0;
}

.markdown table {
  @apply w-full mb-4 border-collapse;
}

.markdown table th, .markdown table td {
  @apply text-base py-2 px-4;
}

.markdown table tr:nth-child(2n) {
  @apply bg-gray-100;
}

.markdown hr {
  @apply mt-6 mb-6 border-t-2 border-gray-300;
}

.markdown a {
  @apply text-blue-500;
}

.markdown a:hover {
  @apply underline;
}

.markdown img {
  max-width: 100%;
  box-sizing: border-box;
}
