.circular-slider div div svg:first-of-type {
	border: 2px solid rgb(204, 204, 204);
	border-radius: 50%;
	background: rgb(204, 204, 204);
}

.half-background-on-cv-detail:before {
	@apply absolute bg-surface left-[-100%] w-full top-0 bottom-0 z-0 right-[100%] content-['']
}

/* For Firefox Browser */
.scrollbar {
	scrollbar-width: thin;
	scrollbar-color: rgb(var(--bj-on-surface-strong) / 0.8) rgb(var(--bj-surface) / var(--tw-bg-opacity));
}
/* For Chrome, EDGE, Opera, Others */
.scrollbar::-webkit-scrollbar {
	width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
	background: rgb(var(--bj-surface) / var(--tw-bg-opacity));
}
.scrollbar::-webkit-scrollbar-thumb {
	background: rgb(var(--bj-on-surface-strong) / 0.8);
	border-radius: 10px;
}

/* For Firefox Browser */
.scrollbar-thin {
	scrollbar-width: thin;
	scrollbar-color: #ccc transparent;
}
/* For Chrome, EDGE, Opera, Others */
.scrollbar-thin::-webkit-scrollbar {
	width: 1px;
	height: 3px;
	border-radius: 10px;
}
.scrollbar-thin::-webkit-scrollbar-thumb {
	background:#ccc;
	border-radius: 10px;
}

/* Hide scrollbar for all elements with the .scrollbar-hide class */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none; /* for Internet Explorer and Edge */
  scrollbar-width: none; /* for Firefox */
}
