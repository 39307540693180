@layer utilities {
	/* VARIABLES */
	/* ************************************************************************************ */
	:root {
		/* measures the offset from the top of viewport */
		--bj-mobile-menu-offset: 58px;
	}


	/* UILITIES */
	/* ************************************************************************************ */
	.h-usable-viewport {
		/* fallback for browsers that do not support dvh */
		height: calc(100vh - var(--bj-mobile-menu-offset));
		height: calc(100dvh - var(--bj-mobile-menu-offset));
	}

	@supports (font: -apple-system-body) and (-webkit-appearance: none) {
		.img-loading-lazy {
			@apply clip-path[inset(0.6px)];
		}
	}


	/* scrollbar width (chrome, edge, firefox, opera) */
	.scrollbar-thin {
		scrollbar-width: thin;
	}
}
